<template>
    <a-drawer
        placement="right"
        :visible="visible"
        title="Настройки виджета"
        class="w_setting_drawer"
        :class="isMobile && 'mobile'"
        :width="drawerWidth"
        destroyOnClose
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <div class="drawer_body">
            <component 
                v-if="widget" 
                ref="widgetConfig"
                :is="widgetComponent"
                :closeSettingDrawer="closeSettingDrawer"
                :widget="widget" />
        </div>
        <div class="drawer_footer">
            <a-button 
                type="primary" 
                :block="isMobile"
                :size="isMobile ? 'large' : 'default'"
                @click="saveConfig()">
                Сохранить
            </a-button>
            <a-button 
                type="ui" 
                :class="isMobile ? 'mt-1' : 'ml-2'" 
                :ghost="isMobile"
                :block="isMobile"
                @click="resetConfig()">
                Сбросить
            </a-button>
            <a-button 
                v-if="!isMobile"
                type="ui" 
                ghost 
                class="ml-2" 
                @click="visible = false">
                Закрыть
            </a-button>
        </div>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    data() {
        return {
            visible: false,
            widget: null
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        drawerWidth() {
            return this.windowWidth >= 700 ? 700 : this.windowWidth
        },
        widgetComponent() {
            if(this.widget?.widget?.setting_component) {
                return () => import(`./SettingWidgets/${this.widget.widget.setting_component}.vue`)
                    .then(module => {
                        return module
                    })
                    .catch(e => {
                        console.log('error')
                        return import(`./SettingWidgets/NotWidget.vue`)
                    })
            }
            return null
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    methods: {
        closeSettingDrawer() {
            this.visible = false
        },
        afterVisibleChange(vis) {
            if(!vis) {
                this.widget = null
            }
        },
        resetConfig() {
            this.$nextTick(() => {
                this.$refs.widgetConfig.resetConfig()
            })
        },
        saveConfig() {
            this.$nextTick(() => {
                this.$refs.widgetConfig.saveConfig()
            })
        }
    },
    mounted() {
        eventBus.$on('openSetting', widget => {
            this.widget = widget
            this.visible = true
        })
    },
    beforeDestroy() {
        eventBus.$off('openSetting')
    }
}
</script>

<style lang="scss" scoped>
.w_setting_drawer{
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
        }
        .ant-drawer-body{
            height: calc(100% - 40px);
            padding: 0px;
        }
        .drawer_body{
            overflow-y: auto;
            padding: 15px;
            height: calc(100% - 40px);
        }
        .drawer_footer{
            border-top: 1px solid var(--border2);
            height: 40px;
            padding-left: 15px;
            padding-right: 15px;
            display: flex;
            align-items: center;
        }
    }
    &.mobile{
        &::v-deep{
            .drawer_body{
                height: calc(100% - 90px);
            }
            .drawer_footer{
                display: block;
                height: 90px;
                padding-bottom: 5px;
                padding-top: 5px;
            }
        }
    }
}
</style>